define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/meetup-form-overrides", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-i18n", "discourse/plugins/discourse-local-guides-connect/discourse/lib/constants", "discourse/plugins/discourse-local-guides-connect/discourse/lib/is-meetup-submission"], function (_exports, _pluginApi, _composer, _discourseI18n, _constants, _isMeetupSubmission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modifyComposer(api) {
    api.customizeComposerText({
      actionTitle(model) {
        let i18nKey;
        const isEdit = model.get("action") === _composer.default.EDIT;
        if ((0, _isMeetupSubmission.default)(model)) {
          if (model.get("category.read_restricted")) {
            i18nKey = isEdit ? "meetup_form.edit_private_title" : "meetup_form.create_private_title";
          } else {
            i18nKey = isEdit || model.get(`topic.${_constants.EVENT_TOPIC_ID_FIELD}`) ? "meetup_form.edit_title" : "meetup_form.create_title";
          }
        } else if (model.get("category.read_restricted")) {
          i18nKey = isEdit ? "composer.edit_private_title" : "composer.create_private_title";
        }
        return i18nKey ? _discourseI18n.default.t(i18nKey) : undefined;
      },
      saveLabel(model) {
        if ((0, _isMeetupSubmission.default)(model)) {
          return "meetup_form.save_label";
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "meetup-form-overrides",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.10.1", modifyComposer);
      const router = container.lookup("service:router");
      router.on("routeWillChange", () => {
        const composer = container.lookup("service:composer");
        if (composer.get("model.viewOpen")) {
          composer.toggle();
        }
      });
    }
  };
});