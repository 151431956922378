define("discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-title/back", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "discourse/helpers/body-class", "discourse-common/helpers/i18n", "discourse/plugins/discourse-local-guides-connect/discourse/helpers/reveal-meetup-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _bodyClass, _i18n, _revealMeetupUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Back extends _component.default {
    goBack() {
      window.history.back();
    }
    static #_ = (() => dt7948.n(this.prototype, "goBack", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless (revealMeetupUrl this.outletArgs.model.event_starts_at)}}
          {{bodyClass "meetup-url-cutoff"}}
        {{/unless}}
    
        {{#unless this.model.isPrivateMessage}}
          <button
            {{on "click" this.goBack}}
            type="button"
            name="back"
            class="g-button--uncontained topic-back-button"
          >
            <span>
              {{i18n "js.back_button"}}
            </span>
          </button>
        {{/unless}}
      
    */
    {
      "id": "ztb0K9Is",
      "block": "[[[1,\"\\n\"],[41,[51,[28,[32,0],[[30,0,[\"outletArgs\",\"model\",\"event_starts_at\"]]],null]],[[[1,\"      \"],[1,[28,[32,1],[\"meetup-url-cutoff\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,0,[\"model\",\"isPrivateMessage\"]]],[[[1,\"      \"],[11,\"button\"],[24,3,\"back\"],[24,0,\"g-button--uncontained topic-back-button\"],[24,4,\"button\"],[4,[32,2],[\"click\",[30,0,[\"goBack\"]]],null],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,3],[\"js.back_button\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/connectors/topic-title/back.js",
      "scope": () => [_revealMeetupUrl.default, _bodyClass.default, _modifier.on, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Back;
});