define("discourse/plugins/discourse-local-guides-connect/discourse/components/like-toggle", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/runloop", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse/helpers/number", "discourse/lib/ajax", "discourse/models/site", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _runloop, _service, _truthHelpers, _concatClass, _number, _ajax, _site, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LikeToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "likeToggled", [_tracking.tracked], function () {
      return this.args.topic.liked;
    }))();
    #likeToggled = (() => (dt7948.i(this, "likeToggled"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "likeCount", [_tracking.tracked], function () {
      return this.args.topic.op_like_count;
    }))();
    #likeCount = (() => (dt7948.i(this, "likeCount"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "clickCounter", [_tracking.tracked], function () {
      return 0;
    }))();
    #clickCounter = (() => (dt7948.i(this, "clickCounter"), void 0))();
    get toggleDisabled() {
      if (!this.args.topics?.first_post_actions_summary) {
        return false;
      }
      const likeActionID = _site.default.current().post_action_types.find(a => a.name_key === "like").id;
      const actions = this.args.topic.first_post_actions_summary.find(_ref => {
        let {
          id
        } = _ref;
        return id === likeActionID;
      });
      if (!actions) {
        return false;
      }
      return !(actions.can_act || actions.can_undo);
    }
    toggleLikeDebounced(topic, event) {
      event.stopPropagation();
      if (this.loading) {
        return;
      }
      this.clickCounter++;
      this.likeToggled = !this.likeToggled;
      this.likeCount += this.likeToggled ? 1 : -1;
      (0, _runloop.debounce)(this, this.performToggleLike, topic, 1000); // 500ms delay
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleLikeDebounced", [_object.action]))();
    async performToggleLike(topic) {
      if (this.clickCounter % 2 === 0) {
        this.clickCounter = 0;
        return;
      }
      this.loading = true;
      try {
        const topicPosts = await (0, _ajax.ajax)(`/t/${topic.id}/post_ids.json`);
        if (topicPosts && topicPosts.post_ids.length) {
          const firstPost = topicPosts.post_ids[0];
          if (firstPost) {
            if (!this.likeToggled) {
              // Adjusted the logic here to match the updated state
              await (0, _ajax.ajax)(`/post_actions/${firstPost}`, {
                type: "DELETE",
                data: {
                  post_action_type_id: 2
                }
              });
            } else {
              await (0, _ajax.ajax)(`/post_actions`, {
                type: "POST",
                data: {
                  id: firstPost,
                  post_action_type_id: 2
                }
              });
            }
          }
        }
      } catch (error) {
        // console.error("Error toggling like:", error);
        // Rollback UI changes in case of an error
        this.likeToggled = !this.likeToggled;
        this.likeCount += this.likeToggled ? 1 : -1;
        this.dialog.alert(this.likeToggled ? "Sorry, you can't remove that like. Please refresh." : "Sorry, you can't like that topic.");
      } finally {
        this.loading = false;
        this.clickCounter = 0;
      }
    }
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <button
          disabled={{or
            (eq @topic.first_poster.username this.currentUser.username)
            (eq (get @topic.posters "0.user.username") this.currentUser.username)
            this.toggleDisabled
            @topic.archived
          }}
          title={{if
            (eq @topic.first_poster.username this.currentUser.username)
            (i18n "post.like_disabled")
            (i18n "post.like")
          }}
          type="button"
          {{on "click" (fn this.toggleLikeDebounced @topic)}}
          class={{concatClass
            (if this.likeToggled "g-topic__like-button--liked")
            "g-topic__like-button"
          }}
        >
          {{#if (eq this.likeCount 0)}}
            <span>
              {{i18n "post.like"}}
            </span>
          {{else}}
            {{number this.likeCount}}
          {{/if}}
        </button>
      
    */
    {
      "id": "3t1Yczc5",
      "block": "[[[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[28,[32,0],[[28,[32,1],[[30,1,[\"first_poster\",\"username\"]],[30,0,[\"currentUser\",\"username\"]]],null],[28,[32,1],[[28,[32,2],[[30,1,[\"posters\"]],\"0.user.username\"],null],[30,0,[\"currentUser\",\"username\"]]],null],[30,0,[\"toggleDisabled\"]],[30,1,[\"archived\"]]],null]],[16,\"title\",[52,[28,[32,1],[[30,1,[\"first_poster\",\"username\"]],[30,0,[\"currentUser\",\"username\"]]],null],[28,[32,3],[\"post.like_disabled\"],null],[28,[32,3],[\"post.like\"],null]]],[16,0,[28,[32,4],[[52,[30,0,[\"likeToggled\"]],\"g-topic__like-button--liked\"],\"g-topic__like-button\"],null]],[24,4,\"button\"],[4,[32,5],[\"click\",[28,[32,6],[[30,0,[\"toggleLikeDebounced\"]],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"likeCount\"]],0],null],[[[1,\"        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,3],[\"post.like\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,7],[[30,0,[\"likeCount\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-local-guides-connect/discourse/components/like-toggle.js",
      "scope": () => [_truthHelpers.or, _truthHelpers.eq, _helper.get, _i18n.default, _concatClass.default, _modifier.on, _helper.fn, _number.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LikeToggle;
});