define("discourse/plugins/discourse-local-guides-connect/discourse/components/timezone-input", ["exports", "select-kit/components/combo-box"], function (_exports, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function prettifyTimezone(ianaTimezone, isLocalized) {
    const value = isLocalized ? ianaTimezone.value : ianaTimezone;
    const name = isLocalized ? ianaTimezone.name : undefined;

    // Skip generic entries
    if (value.startsWith("Etc/")) {
      return null;
    }
    const options = {
      timeZone: value,
      timeZoneName: "longGeneric"
    };
    const locale = "en-us";
    const offset = moment.tz(value).format("Z");
    const numericOffset = parseInt(offset.replace(":", ""), 10);
    try {
      const formatter = new Intl.DateTimeFormat(locale, options);
      const parts = formatter.formatToParts(new Date());
      const tzName = parts.find(part => part.type === "timeZoneName").value;
      let cityStr;
      if (name) {
        cityStr = ` - ${name}`;
      } else {
        const timezoneParts = ianaTimezone.split("/");
        const city = timezoneParts[timezoneParts.length - 1].replace(/_/g, " ");
        cityStr = city ? ` - ${city}` : "";
      }
      return {
        name: `(${offset}) ${tzName} ${cityStr}`,
        value,
        offset: numericOffset
      };
    } catch (e) {
      return {
        name: value,
        value,
        offset: numericOffset
      };
    }
  }
  class TimezoneInput extends _comboBox.default {
    static pluginApiIdentifiers = ["timezone-input"];
    static classNames = ["timezone-input"];
    static selectKitOptions = {
      filterable: true,
      allowAny: false
    };
    get nameProperty() {
      return "name";
    }
    get valueProperty() {
      return "value";
    }
    get names() {
      const names = this.isLocalized ? moment.tz.localizedNames() : moment.tz.names();
      return names.map(name => prettifyTimezone(name, this.isLocalized)).filter(Boolean).sort((a, b) => a.offset - b.offset || a.name.localeCompare(b.name));
    }
    get content() {
      return this.names;
    }
    get isLocalized() {
      return moment.locale() !== "en" && typeof moment.tz.localizedNames === "function";
    }
  }
  _exports.default = TimezoneInput;
});